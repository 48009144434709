<template>
<div class="">
  <div class="row row-no-padding align-items-center m-0 p-0">
    <div class="exhibition-content w-100 mx-lg-4 mx-sm-0 pt-4">
      <div class=" row m-0 px-4">

        <!-- <exhibition-chats :selectedExhibitor="selectedExhibitor" :updateselectedExhibitor="updateselectedExhibitor"></exhibition-chats> -->
          <div class="col-md-4 left-side m-0 p-0">
            <div class="header-container w-100">
              <div :class="['px-2','status-button','selected-status']"><p>{{$t('views.exhibits')}}</p></div>
            </div>
            <div class="people-container">
              <div v-for="(exhibit, index) in exhibits" :key="index" :class="['people', currentExhibit.uuid == exhibit.uuid ? 'selected-person' : 'not-selected-person']" @click="updateselectedExhibit(exhibit)">
                <div class="person-info white-text ml-2 mt-2">
                  <p class="p-0 m-0 main-text" v-html="exhibit.title"></p>
                  <p class="p-0 m-0 sub-text" v-html="exhibit.note_text"></p>
                </div>
                <div
                  :class="['row','row-no-padding','align-items-center','h-100','message-icon-container',currentExhibit.uuid == exhibit.uuid ? 'selected-message-icon-container' : 'not-selected-message-icon-container']"
                >
                  <img src="@/assets/Icons/Icons/Icon-Exhibit.svg" class="message-icon" height="28" width="25">
                </div>
              </div>
            </div>
            <!-- <div v-else class="no_note no_note_group">
              <img class src="@/assets/images/Icon-Notes-grey.svg" />
              <p v-html="$t('views.searching.no_results')"></p>
            </div> -->
          </div>


        <hr class="mobile-seperator">

        <!--PRODUCT MODEL-->
        <div class="col-md-8 right-side">
            <router-view v-if="currentExhibit.uuid || selectedExhibitor" 
              :showNoteMessage ="showNoteMessage" 
              :setSelectedExhibit="setSelectedExhibit" 
              :selectedExhibit="currentExhibit" 
              :goToNotes="goToNotes" 
              :setSelectedNote="setSelectedNote" 
              :setSelectedUser="setSelectedUser" 
              :closeProduct="closeProduct" 
              :toggleShowProduct="toggleShowProduct" 
              :showProduct="showProduct" 
              :toggleShowChat="toggleShowChat" >
            </router-view>
            <div v-else class="no_note no_note_content">
              <img class src="@/assets/images/Icon-Search-grey.svg" />
              <p v-html="$t('views.searching.no_selected')"></p>
            </div>
        </div>

      </div>
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: 'search',
  components: {
  },
  props: {
    toggleShowChat: Function,
    setSelectedUser: Function,
    closeProduct: Function,
    showProduct: Boolean,
    toggleShowProduct: Function,
    selectedNote: String,
    setSelectedNote: Function,
    goToNotes: Function,
    selectedExhibit: Object,
    setSelectedExhibit: Function,
    showNoteMessage: Function

  },
  data() {
    return {
      selectedExhibitor: '',
      selectedStatus: 1,
      exhibits: [],
      exhibitors: [],
      currentExhibit: {}
    };
  },
  methods: {
    updateselectedExhibitor(exhibitor) {
      this.selectedExhibitor = exhibitor.uuid;
      this.$router.replace('/search/exhibitor/' + exhibitor.uuid + '?searchQuery=' + this.$route.query.searchQuery);
    },
    updateselectedExhibit(exhibit) {
      this.currentExhibit = exhibit;
      this.$router.replace('/search/exhibit/' + exhibit.uuid + '?searchQuery=' + this.$route.query.searchQuery);
    },
    toggleProduct() {
      this.showProduct = true;
    },
    getNotes(){
      this.$http.get('/notes')
        .then(res => {
          this.notes = res.data;
        })
        .catch();
    },
    deleteNote(exhibit) {
      this.$http( { method: 'delete', url: '/note?uuid=' + exhibit.note_uuid } )
        .then(() => {
          this.getNotes();
        })
        .catch(err => console.log(err));
    },
    handleChildDeletion(event){
      this.deleteNote(event);
    } 
  },
  watch: {
    '$route.query.searchQuery'(to){
      this.$http.get('/search?search=' + to)
        .then(res => {
          this.exhibits =  res.data.exhibits;
          this.exhibitors = res.data.exhibitors;
        })
        .catch();
    }
  },
  created() {
    this.setSelectedExhibit({uuid: ''});
    this.$http.get('/search?search=' + this.$route.query.searchQuery)
      .then(res => {
        this.exhibits =  res.data.exhibits;
        this.exhibitors = res.data.exhibitors;

        if( this.$route.name == 'exhibit' && this.$route.params.id){

          this.exhibits.forEach(exhibit => {
            if( this.$route.params.id == exhibit.uuid )
              this.currentExhibit = exhibit;

          });
        } else if( this.$route.name == 'exhibitors' && this.$route.params.id ) {
          
          this.exhibitors.forEach(exhibitor => {
            if( this.$route.params.id == exhibitor.uuid )
              this.selectedExhibitor = exhibitor.uuid;
          });
        }
      })
      .catch();
  }
};
</script>

<style lang='scss' scoped>

  html {
      height: 100%;
  }

  hr {
    background-color: rgba(255, 255, 255, 0.5);
    width: 80%;
  }

  button:hover {
    background-color: $secondaryColor;
  }

  .mobile-seperator {
    display: none;
  }

  .exhibition-content {
    background-color: #000000E6;
    min-height: calc(100vh - 124px);
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-top: 50px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
.no_note {
  color: grey;
  margin: auto auto;
  padding-top: 20%;

  img {
    width: 40px;
    margin-bottom: 15px;
  }

  &_content {
    padding-top: 17%;
    width: 70%;
    font-size: 1rem;
    text-align: center;
    align-self: center;
  }
  &_group {
    font-size: 1rem;
    text-align: center;
    align-self: center;

    img{
      width: 50px;
    }
  }
}
/* -----------EXHIBITORS AND EXHIBITS CSS-------------- */

  .left-side{
    max-height: calc(100vh - 160px);
    min-height: calc(100vh - 160px);
    overflow-y: scroll;
  }

  .right-side {
    height: calc(100vh - 160px) !important;
  }

  .selected-status {
    color: black;
    background-color: $secondaryColor;
  }

  .header-container{
    width: 90% !important;
    padding-right: 55px;
  }

  .lower{
    padding-top: 91px;
  }

  .status-button {
    font-size: 16px;
    height: 34px;
    font-weight: 400;
    padding-top: 5px;
    margin-left: 20px;
    width: 100%;
  }

  .people-container {
    width: 83.5% !important;
    margin-top: 41px !important;
    margin-left: 20px !important;
    margin-right: 15px !important;
    padding-left: 0 !important;
    padding-right: 15px !important;
  }

  .person-info {
    font-size: 16px;
    display: inline-block;
    white-space: nowrap;
    width: 84%;
    height: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis;
    font-weight: 400;
    letter-spacing: 0.15px;
  }

  .people {
    margin-top: 20px !important;
    min-height: 25px;
    border: 1px solid;
    width: 100%;
    position: relative;
    padding: 0% 1%;
  }

  .no-margin{
    margin-top: 0px !important;
  }

  .not-selected-person {
    border-color: white;
  }

  .message-icon-container {
    width: 60px;
    position: absolute;
    right:0;
    top:0;
  }

  .not-selected-message-icon-container {
    background-color: white;
  }

  .people:hover {
    cursor: pointer;
    border-color: $secondaryColor;
  }

  .people:hover .message-icon-container {
    background-color: $secondaryColor;
  }

  .selected-message-icon-container {
    background-color: $secondaryColor !important;
  }

  .selected-person {
    border-color: $secondaryColor !important;
  }

  .message-icon {
    width: 100%;
    text-align: center;
  }

  .row-no-padding {
    margin-left: 0;
    margin-right: 0;
  }

  .sub-text {
    width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

/* -----------START NOTE CSS-------------- */
  .notes{
    width: 100%;
  }
/* -----------END NOTE CSS-------------- */


  @media only screen and (min-device-width : 360px) and (max-device-width : 640px){
    p {
      font-size: 10px !important;
    }

    .exhibition-content{
      height: 2000px;
    }

  }

  @media screen and (max-width: 760px) {

    .exhibition-content {
      padding-bottom: 50px;
    }

    .button-icon {
      margin-right: 0px !important;
      padding-right: 0px !important;
    }

  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .header-container {
      width: 96% !important;
    }
  }

  @media only screen and (min-width: 1030px) and (max-width: 1366px) { 
    .header-container {
      width: 93.5% !important;
    }
  }
</style>
